<template>
  <div class="login-container">
    <h1>Авторизация</h1>
    <form @submit.prevent="handleLogin">
      <div class="input-group">
        <label for="username">Имя пользователя</label>
        <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Введите имя пользователя"
            required
        />
      </div>
      <div class="input-group">
        <label for="password">Пароль</label>
        <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Введите пароль"
            required
        />
      </div>
      <button type="submit" :disabled="isLoading">
        <span v-if="isLoading">Загрузка...</span>
        <span v-else>Вход</span>
      </button>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      isLoading: false,
    };
  },
  methods: {
    async handleLogin() {
      this.isLoading = true; // Показать индикатор загрузки
      this.errorMessage = ''; // Сбросить сообщение об ошибке
      try {
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });
        this.$router.push('/'); // Перенаправление на главную страницу
      } catch (error) {
        console.error('Ошибка при входе:', error);
        // Улучшенная обработка ошибки
        this.errorMessage =
            error.response?.data?.message ||
            'Не удалось войти. Проверьте имя пользователя и пароль.';
      } finally {
        this.isLoading = false; // Скрыть индикатор загрузки
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333333;
  margin-bottom: 25px;
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  color: #333333;
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  font-size: 16px;
}

button {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: none;
  background-color: #228b22;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover {
  background-color: #006400;
}

.error {
  color: red;
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
}
</style>
