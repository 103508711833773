<template>
  <nav class="navbar">
    <router-link to="/">Главная</router-link>
    <router-link v-if="!isAuthenticated" to="/login">Вход</router-link>
    <router-link v-if="isAdmin" to="/admin">Админка</router-link>
    <router-link v-if="isAuthenticated" to="/profile">Профиль</router-link>
    <button v-if="isAuthenticated" @click="logout">Выход</button>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  gap: 15px;
  padding: 10px 20px;
  background-color: #333333;
  align-items: center;
}

.navbar a, button {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
}

.navbar a:hover, button:hover {
  background-color: #555555;
  border-radius: 4px;
}

button {
  background-color: #ff4d4d;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff1a1a;
}
</style>
