<template>
  <div class="books-list">
    <h2>Список Книг</h2>

    <div v-if="loading" class="loading">Загрузка...</div>

    <div v-else>
      <div class="search">
        <input
            type="text"
            v-model="searchTitle"
            placeholder="Поиск по названию книги"
            @input="loadBooks"
        />
      </div>

      <ul>
        <li v-for="book in books" :key="book.id">
          <h3>{{ book.title }}</h3>
          <p>Автор: {{ book.author }}</p>
          <!-- Add more book details as needed -->
          <button @click="viewBookDetails(book.id)">Посмотреть детали</button>
        </li>
      </ul>

      <!-- Pagination controls -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 0">Назад</button>
        <span>Страница {{ currentPage + 1 }} из {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage + 1 >= totalPages">Вперед</button>
      </div>
    </div>
  </div>
</template>

<script>
import BookService from '@/services/BookService';

export default {
  name: 'BooksList',
  data() {
    return {
      books: [],
      searchTitle: '',
      loading: true,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      pageSize: 10,
    };
  },
  methods: {
    async loadBooks() {
      this.loading = true;
      try {
        const response = await BookService.getBooks(this.searchTitle, this.currentPage, this.pageSize);
        const data = response.data;
        this.books = data.books || data.content;
        this.currentPage = data.currentPage || data.number;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalItems || data.totalElements;
      } catch (error) {
        console.error('Ошибка при загрузке книг:', error);
      } finally {
        this.loading = false;
      }
    },
    viewBookDetails(bookId) {
      this.$router.push(`/books/${bookId}`);
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.loadBooks();
      }
    },
    nextPage() {
      if (this.currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.loadBooks();
      }
    },
  },
  created() {
    this.loadBooks();
  },
};
</script>

<style scoped>
.books-list {
  padding: 20px;
}

.search {
  margin-bottom: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #666;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
}

.pagination span {
  font-size: 16px;
}
</style>
