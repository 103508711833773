import api from '@/services/api';

export default {
    getShelves(page = 0, size = 10) {
        return api.get('/shelves', {
            params: {
                page: page,
                size: size,
            },
        });
    },

    addShelf(shelf) {
        return api.post('/shelves', shelf);
    },

    deleteShelf(shelfId) {
        return api.delete(`/shelves/${shelfId}`);
    },
};
