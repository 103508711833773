<template>
  <div class="admin-shelves">
    <h2>Управление полками</h2>
    <form @submit.prevent="addShelf">
      <div class="input-group">
        <label for="shelfName">Название полки:</label>
        <input type="text" id="shelfName" v-model="newShelf.name" required/>
      </div>
      <button type="submit">Добавить полку</button>
    </form>

    <ul>
      <li v-for="shelf in shelves" :key="shelf.id">
        {{ shelf.name }}
        <button @click="removeShelf(shelf.id)">Удалить</button>
      </li>
    </ul>

    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 0">Назад</button>
      <span>Страница {{ currentPage + 1 }} из {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage + 1 >= totalPages">Вперед</button>
    </div>
  </div>
</template>

<script>
import ShelfService from '@/services/ShelfService';

export default {
  name: 'AdminShelves',
  data() {
    return {
      newShelf: {name: ''},
      shelves: [], // Список полок
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      pageSize: 10,
    };
  },
  methods: {
    async loadShelves() {
      try {
        const response = await ShelfService.getShelves(this.currentPage, this.pageSize);
        const data = response.data;
        this.shelves = data.content;
        this.currentPage = data.number;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalElements;
      } catch (error) {
        console.error('Ошибка при загрузке полок:', error);
      }
    },
    async addShelf() {
      try {
        await ShelfService.addShelf(this.newShelf);
        this.newShelf.name = '';
        this.loadShelves();
      } catch (error) {
        console.error('Ошибка при добавлении полки:', error);
      }
    },
    async removeShelf(shelfId) {
      try {
        await ShelfService.deleteShelf(shelfId);
        this.loadShelves();
      } catch (error) {
        console.error('Ошибка при удалении полки:', error);
      }
    },
    // Перейти на предыдущую страницу
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.loadShelves();
      }
    },
    // Перейти на следующую страницу
    nextPage() {
      if (this.currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.loadShelves();
      }
    },
  },
  created() {
    this.loadShelves(); // Загрузка полок при создании компонента
  },
};
</script>

<style scoped>
.admin-shelves {
  padding: 20px;
}

.input-group {
  margin-bottom: 15px;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li button {
  background-color: #dc3545;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

li button:hover {
  background-color: #c82333;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
}

.pagination span {
  font-size: 16px;
}
</style>
