<template>
  <div className="home-container">
    <h1>Добро пожаловать в библиотечную систему!</h1>

    <div v-if="!isAuthenticated">
      <p>Вы не вошли в систему. Пожалуйста,
        <router-link to="/login">войдите</router-link>
        .
      </p>
    </div>

    <div v-else>
      <h2>Информация о пользователе:</h2>
      <p><strong>Имя пользователя:</strong> {{ user.username }}</p>
      <p><strong>Email:</strong> {{ user.email }}</p>
      <hr/>

      <BooksList/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'; // Импортируем mapActions для вызова действий Vuex
import BooksList from './user/BookList';

export default {
  name: 'HomePage',
  components: {
    BooksList,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user']), // Используем getters из Vuex для получения данных
  },
  methods: {
    ...mapActions(['fetchUserProfile']), // Вызов действия Vuex для получения данных пользователя
  },
  created() {
    // Проверяем аутентификацию
    if (this.isAuthenticated) {
      this.fetchUserProfile(); // Загружаем профиль пользователя через Vuex
    } else {
      this.$router.push('/login'); // Перенаправление на страницу входа, если пользователь не аутентифицирован
    }
  },
};
</script>

<style scoped>
.home-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  color: #333333;
  margin-bottom: 20px;
}

h2, h3 {
  color: #555555;
  margin-top: 30px;
}

p {
  color: #666666;
  font-size: 18px;
}

.error {
  color: red;
  margin-top: 10px;
}
</style>
