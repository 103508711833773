<!-- src/components/admin/AdminUsers.vue -->
<template>
  <div class="admin-users">
    <h2>Управление пользователями</h2>
    <!-- Список пользователей -->
    <ul>
      <li v-for="user in users" :key="user.id">
        <strong>{{ user.username }}</strong> - {{ user.email }}
        <button @click="openEditUserModal(user)">Редактировать</button>
        <button @click="removeUser(user.id)">Удалить</button>
      </li>
    </ul>

    <!-- Модальное окно редактирования пользователя -->
    <div v-if="showEditUserModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeEditUserModal">&times;</span>
        <h3>Редактировать пользователя</h3>
        <form @submit.prevent="updateUser">
          <div class="input-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="editUser.email" required />
          </div>
          <!-- Добавьте дополнительные поля по необходимости -->
          <button type="submit">Сохранить изменения</button>
        </form>
      </div>
    </div>

    <!-- Элементы управления пагинацией -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 0">Назад</button>
      <span>Страница {{ currentPage + 1 }} из {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage + 1 >= totalPages">Вперед</button>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/UserService';

export default {
  name: 'AdminUsers',
  data() {
    return {
      users: [],
      showEditUserModal: false,
      editUser: {},
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      pageSize: 10,
    };
  },
  methods: {
    // Загрузка списка пользователей
    async loadUsers() {
      try {
        const response = await UserService.getUsers(this.currentPage, this.pageSize);
        const data = response.data;
        this.users = data.users || data.content;
        this.currentPage = data.currentPage || data.number;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalItems || data.totalElements;
      } catch (error) {
        console.error('Ошибка при загрузке пользователей:', error);
      }
    },
    // Открыть модальное окно редактирования пользователя
    openEditUserModal(user) {
      this.editUser = { ...user };
      this.showEditUserModal = true;
    },
    // Закрыть модальное окно редактирования пользователя
    closeEditUserModal() {
      this.showEditUserModal = false;
      this.editUser = {};
    },
    // Обновление пользователя
    async updateUser() {
      try {
        await UserService.updateUser(this.editUser.id, this.editUser);
        this.closeEditUserModal();
        this.loadUsers();
      } catch (error) {
        console.error('Ошибка при обновлении пользователя:', error);
      }
    },
    // Удаление пользователя
    async removeUser(userId) {
      try {
        await UserService.deleteUser(userId);
        this.loadUsers();
      } catch (error) {
        console.error('Ошибка при удалении пользователя:', error);
      }
    },
    // Пагинация
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.loadUsers();
      }
    },
    nextPage() {
      if (this.currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.loadUsers();
      }
    },
  },
  created() {
    this.loadUsers();
  },
};
</script>

<style scoped>
.admin-users {
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li button {
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 4px;
  cursor: pointer;
}

li button:hover {
  background-color: #5a6268;
}

/* Стили для модального окна */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  width: 400px;
  border-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
}

.pagination span {
  font-size: 16px;
}
</style>
