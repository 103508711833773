import Vuex from 'vuex';
import api from '@/services/api';
import router from '@/router';

export default new Vuex.Store({
    state: {
        accessToken: localStorage.getItem('accessToken') || '',
        user: {},
    },
    mutations: {
        SET_ACCESS_TOKEN(state, token) {
            state.accessToken = token;
            localStorage.setItem('accessToken', token);
        },
        SET_USER(state, user) {
            state.user = user;
        },
        CLEAR_AUTH(state) {
            state.accessToken = '';
            state.user = {};
            localStorage.removeItem('accessToken');
        },
    },
    actions: {
        async login({ commit, dispatch }, authData) {
            try {
                // Вход пользователя и сохранение accessToken
                const response = await api.post('/auth/login', authData);
                commit('SET_ACCESS_TOKEN', response.data.accessToken);

                // После успешного входа, загрузка профиля пользователя
                await dispatch('fetchUserProfile');

                // Перенаправление в зависимости от роли
                if (response.data.roles?.includes('ROLE_ADMIN')) {
                    router.push('/admin');
                } else {
                    router.push('/');
                }
            } catch (error) {
                console.error('Ошибка при входе:', error);
                throw error;
            }
        },
        async fetchUserProfile({ commit, state }) {
            try {
                // Получение данных профиля пользователя
                const response = await api.get('/users/profile', {
                    headers: { Authorization: `Bearer ${state.accessToken}` },
                });
                commit('SET_USER', response.data);
            } catch (error) {
                console.error('Ошибка при получении данных профиля:', error);
                commit('CLEAR_AUTH');
                throw error;
            }
        },
        logout({ commit }) {
            commit('CLEAR_AUTH');
            api.post('/auth/logout').then(() => {
                router.push('/login');
            });
        },
    },
    getters: {
        isAuthenticated: state => !!state.accessToken,
        isAdmin: state => Array.isArray(state.user?.roles) && state.user.roles.includes('ROLE_ADMIN'),
        user: state => state.user,
    },
});
