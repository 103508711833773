import api from './api'; // Ваш настроенный Axios-инстанс

class UserService {
    async getUserProfile() {
        const response = await api.get('/users/profile'); // Эндпоинт для получения данных текущего пользователя
        return response.data;
    }

    async updateUserProfile(profileData) {
        const response = await api.put('/users/profile', profileData); // Эндпоинт для обновления данных пользователя
        return response.data;
    }

    async changePassword(passwordData) {
        const response = await api.put('/users/change-password', passwordData); // Эндпоинт для смены пароля
        return response.data;
    }

    getUsers(page = 0, size = 10) {
        return api.get('/users', {
            params: {
                page: page,
                size: size,
            },
        });
    }

    updateUser(userId, user) {
        return api.put(`/users/${userId}`, user);
    }

    deleteUser(userId) {
        return api.delete(`/users/${userId}`);
    }
}

export default new UserService();
