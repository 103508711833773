<!-- src/components/admin/AdminStatistics.vue -->
<template>
  <div class="admin-statistics">
    <h2>Статистика</h2>
    <div class="stats-grid">
      <div class="stat-card">
        <h3>Пользователи</h3>
        <p>{{ statistics.totalUsers }}</p>
      </div>
      <div class="stat-card">
        <h3>Книги</h3>
        <p>{{ statistics.totalBooks }}</p>
      </div>
      <div class="stat-card">
        <h3>Полки</h3>
        <p>{{ statistics.totalShelves }}</p>
      </div>
      <!-- Добавьте дополнительные карточки статистики при необходимости -->
    </div>
  </div>
</template>

<script>
import StatisticsService from '@/services/StatisticsService';

export default {
  name: 'AdminStatistics',
  data() {
    return {
      statistics: {},
    };
  },
  methods: {
    async loadStatistics() {
      try {
        const response = await StatisticsService.getStatistics();
        this.statistics = response.data;
      } catch (error) {
        console.error('Ошибка при загрузке статистики:', error);
      }
    },
  },
  created() {
    this.loadStatistics();
  },
};
</script>

<style scoped>
.admin-statistics {
  padding: 20px;
}

.stats-grid {
  display: flex;
  gap: 20px;
}

.stat-card {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  flex: 1;
  text-align: center;
}

.stat-card h3 {
  margin-bottom: 10px;
}

.stat-card p {
  font-size: 24px;
  font-weight: bold;
}
</style>
