<template>
  <div class="access-denied-container">
    <h1>Доступ запрещён</h1>
    <p>У вас нет прав для доступа к этой странице.</p>
    <button @click="goHome">Вернуться на главную</button>
  </div>
</template>

<script>
export default {
  name: 'AccessDenied',
  methods: {
    goHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.access-denied-container {
  text-align: center;
  margin-top: 100px;
}

h1 {
  color: #ff0000;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  background-color: #228b22;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #006400;
}
</style>
