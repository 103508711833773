<template>
  <div class="book-details">
    <h2>Детали Книги</h2>
    <div v-if="loading" class="loading">Загрузка...</div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
    <div v-if="book">
      <h3>{{ book.title }}</h3>
      <p><strong>Автор:</strong> {{ book.author }}</p>
      <p><strong>Описание:</strong> {{ book.description }}</p>
      <p><strong>Полка:</strong> {{ book.shelf ? book.shelf.name : 'Не указано' }}</p>
      <!-- Add more details as needed -->
      <button @click="goBack">Назад</button>
    </div>
  </div>
</template>

<script>
import BookService from '@/services/BookService';

export default {
  name: 'BookDetails',
  data() {
    return {
      book: null,
      loading: true,
      errorMessage: '',
    };
  },
  methods: {
    // Fetch book details by ID
    async fetchBookDetails() {
      this.loading = true;
      try {
        const response = await BookService.getBookById(this.$route.params.id);
        this.book = response.data;
      } catch (error) {
        this.errorMessage = 'Ошибка при загрузке деталей книги';
        console.error('Ошибка при получении деталей книги:', error);
      } finally {
        this.loading = false;
      }
    },
    // Go back to the previous page
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.fetchBookDetails();
  },
};
</script>

<style scoped>
.book-details {
  padding: 20px;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #666;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

button {
  margin-top: 20px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #228b22;
  color: #ffffff;
  cursor: pointer;
}

button:hover {
  background-color: #006400;
}
</style>
