<template>
  <div class="profile-container">
    <h1>Профиль пользователя</h1>
    <form @submit.prevent="handleUpdateProfile">

      <!-- Исключите поле username или сделайте его только для чтения -->
      <div class="input-group">
        <label for="username">Имя пользователя</label>
        <input
            type="text"
            id="username"
            v-model="user.username"
            readonly
            disabled
        />
      </div>

      <div class="input-group">
        <label for="email">Email</label>
        <input
            type="email"
            id="email"
            v-model="profile.email"
            placeholder="Email"
            required
        />
      </div>
      <button type="submit">Сохранить изменения</button>
      <p v-if="updateMessage" class="success">{{ updateMessage }}</p>
      <p v-if="updateError" class="error">{{ updateError }}</p>
    </form>

    <!-- Остальная часть компонента -->
  </div>
</template>

<script>
import UserService from '@/services/UserService';

export default {
  name: 'UserProfile',
  data() {
    return {
      user: {
        username: '',
        email: '',
      },
      profile: {
        email: '',
      },
      passwords: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      updateMessage: '',
      updateError: '',
      passwordMessage: '',
      passwordError: '',
    };
  },
  methods: {
    async fetchUserProfile() {
      try {
        const userData = await UserService.getUserProfile();
        // console.log('User Data:', userData);
        this.user.username = userData.username;
        this.user.email = userData.email;
        this.profile.email = userData.email;
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
        this.$router.push('/login');
      }
    },
    async handleUpdateProfile() {
      this.updateMessage = '';
      this.updateError = '';
      try {
        await UserService.updateUserProfile(this.profile);
        this.updateMessage = 'Данные успешно обновлены!';
        this.user.email = this.profile.email;
      } catch (error) {
        console.error('Ошибка при обновлении профиля:', error);
        this.updateError = error.response?.data?.message || 'Не удалось обновить данные.';
      }
    },
    // Остальные методы...
  },
  created() {
    this.fetchUserProfile();
  },
};
</script>

<style scoped>
.profile-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  color: #333333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  color: #333333;
  margin-bottom: 8px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #f9f9f9;
  font-size: 16px;
}

button {
  padding: 12px 20px;
  border-radius: 5px;
  border: none;
  background-color: #228b22;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #006400;
}

.success {
  color: green;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}
</style>
