import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import HomePage from '@/components/HomePage.vue';
import Login from '@/components/LoginPage';
import Profile from '@/components/user/UserProfile.vue';
import AdminDashboard from '@/components/admin/AdminDashboard.vue';
import AccessDenied from '@/components/AccessDenied.vue';
import AdminBooks from "@/components/admin/AdminBooks";
import AdminShelves from "@/components/admin/AdminShelves";
import AdminStats from "@/components/admin/AdminStats";
import AdminUsers from "@/components/admin/AdminUsers";
import BookDetails from "@/components/user/BookDetails";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {requiresAuth: true},
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {guest: true},
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {requiresAuth: true},
    },
    {
        path: '/admin',
        name: 'AdminDashboard',
        component: AdminDashboard,
        meta: { requiresAuth: true, roles: ['ROLE_ADMIN'] },
        children: [
            { path: 'books', name: 'AdminBooks', component: AdminBooks, meta: { requiresAuth: true, roles: ['ROLE_ADMIN'] } },
            { path: 'shelves', name: 'AdminShelves', component: AdminShelves, meta: { requiresAuth: true, roles: ['ROLE_ADMIN'] } },
            { path: 'stats', name: 'AdminStats', component: AdminStats, meta: { requiresAuth: true, roles: ['ROLE_ADMIN'] } },
            { path: 'users', name: 'AdminUsers', component: AdminUsers, meta: { requiresAuth: true, roles: ['ROLE_ADMIN'] } },
        ]
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        component: AccessDenied,
    },
    { path: '/books/:id', name: 'BookDetails', component: BookDetails },
    { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Навигационный охранник
router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;
    const isAdmin = store.getters.isAdmin;

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({name: 'Login', query: {redirect: to.fullPath}});
        } else if (to.matched.some(record => record.meta.requiresAdmin) && !isAdmin) {
            next({name: 'AccessDenied'});
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.guest) && isAuthenticated) {
        next({name: 'Home'});
    } else {
        next();
    }
});

export default router;
