<template>
  <div class="admin-books">
    <h2>Управление книгами</h2>
    <!-- Кнопка для открытия модального окна добавления книги -->
    <button @click="showAddBookModal = true">Добавить книгу</button>

    <!-- Модальное окно добавления книги -->
    <div v-if="showAddBookModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeAddBookModal">&times;</span>
        <h3>Добавить новую книгу</h3>
        <form @submit.prevent="addBook">
          <div class="input-group">
            <label for="title">Название книги:</label>
            <input type="text" id="title" v-model="newBook.title" required />
          </div>
          <div class="input-group">
            <label for="author">Автор:</label>
            <input type="text" id="author" v-model="newBook.author" required />
          </div>
          <div class="input-group">
            <label for="description">Описание:</label>
            <textarea id="description" v-model="newBook.description"></textarea>
          </div>
          <!-- Выбор полки не обязателен -->
          <div class="input-group">
            <label for="shelf">Полка (опционально):</label>
            <select id="shelf" v-model="selectedShelfId">
              <option :value="null">Без полки</option>
              <option v-for="shelf in shelves" :key="shelf.id" :value="shelf.id">
                {{ shelf.name }}
              </option>
            </select>
          </div>
          <button type="submit">Добавить книгу</button>
        </form>
      </div>
    </div>

    <!-- Поиск и фильтрация -->
    <div class="filters">
      <input type="text" v-model="searchTitle" placeholder="Поиск по названию" @input="loadBooks" />
      <select v-model="filterShelfId" @change="loadBooks">
        <option :value="null">Все полки</option>
        <option v-for="shelf in shelves" :key="shelf.id" :value="shelf.id">
          {{ shelf.name }}
        </option>
      </select>
    </div>

    <!-- Список книг -->
    <ul>
      <li v-for="book in books" :key="book.id">
        <strong>{{ book.title }}</strong> by {{ book.author }} (Полка: {{ book.shelf?.name || 'Без полки' }})
        <button @click="openEditBookModal(book)">Редактировать</button>
        <button @click="removeBook(book.id)">Удалить</button>
      </li>
    </ul>

    <!-- Модальное окно редактирования книги -->
    <div v-if="showEditBookModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeEditBookModal">&times;</span>
        <h3>Редактировать книгу</h3>
        <form @submit.prevent="updateBook">
          <div class="input-group">
            <label for="edit-title">Название книги:</label>
            <input type="text" id="edit-title" v-model="editBook.title" required />
          </div>
          <div class="input-group">
            <label for="edit-author">Автор:</label>
            <input type="text" id="edit-author" v-model="editBook.author" required />
          </div>
          <div class="input-group">
            <label for="edit-description">Описание:</label>
            <textarea id="edit-description" v-model="editBook.description"></textarea>
          </div>
          <!-- Выбор полки опционален -->
          <div class="input-group">
            <label for="edit-shelf">Полка (опционально):</label>
            <select id="edit-shelf" v-model="editSelectedShelfId">
              <option :value="null">Без полки</option>
              <option v-for="shelf in shelves" :key="shelf.id" :value="shelf.id">
                {{ shelf.name }}
              </option>
            </select>
          </div>
          <button type="submit">Сохранить изменения</button>
        </form>
      </div>
    </div>

    <!-- Элементы управления пагинацией -->
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 0">Назад</button>
      <span>Страница {{ currentPage + 1 }} из {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage + 1 >= totalPages">Вперед</button>
    </div>
  </div>
</template>

<script>
import BookService from '@/services/BookService';
import ShelfService from '@/services/ShelfService';

export default {
  name: 'AdminBooks',
  data() {
    return {
      showAddBookModal: false,
      showEditBookModal: false,
      newBook: {
        title: '',
        author: '',
        description: '',
      },
      editBook: {},
      selectedShelfId: null,
      editSelectedShelfId: null,
      shelves: [],
      books: [],
      searchTitle: '',
      filterShelfId: null,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      pageSize: 10,
    };
  },
  methods: {
    // Загрузка списка полок
    async loadShelves() {
      try {
        const response = await ShelfService.getShelves(0, 100); // Загружаем все полки
        this.shelves = response.data.content || response.data.shelves || response.data;
      } catch (error) {
        console.error('Ошибка при загрузке полок:', error);
      }
    },
    // Загрузка списка книг с пагинацией, фильтрацией и поиском
    async loadBooks() {
      try {
        const params = {
          page: this.currentPage,
          size: this.pageSize,
          title: this.searchTitle,
          shelfId: this.filterShelfId,
        };
        const response = await BookService.getBooks(params);
        const data = response.data;
        this.books = data.books || data.content;
        this.currentPage = data.currentPage || data.number;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalItems || data.totalElements;
      } catch (error) {
        console.error('Ошибка при загрузке книг:', error);
      }
    },
    // Добавление новой книги
    async addBook() {
      try {
        const shelfId = this.selectedShelfId || null;
        await BookService.addBook(this.newBook, shelfId);
        this.newBook = { title: '', author: '', description: '' };
        this.selectedShelfId = null;
        this.closeAddBookModal();
        this.loadBooks(); // Обновляем список книг
      } catch (error) {
        console.error('Ошибка при добавлении книги:', error);
      }
    },
    // Открыть модальное окно редактирования книги
    openEditBookModal(book) {
      this.editBook = { ...book };
      this.editSelectedShelfId = book.shelf ? book.shelf.id : null;
      this.showEditBookModal = true;
    },
    // Закрыть модальное окно редактирования книги
    closeEditBookModal() {
      this.showEditBookModal = false;
      this.editBook = {};
      this.editSelectedShelfId = null;
    },
    // Обновление книги
    async updateBook() {
      try {
        const updatedBook = { ...this.editBook };
        if (this.editSelectedShelfId) {
          updatedBook.shelf = { id: this.editSelectedShelfId };
        } else {
          updatedBook.shelf = null;
        }
        await BookService.updateBook(this.editBook.id, updatedBook);
        this.closeEditBookModal();
        this.loadBooks();
      } catch (error) {
        console.error('Ошибка при обновлении книги:', error);
      }
    },
    // Удаление книги
    async removeBook(bookId) {
      try {
        await BookService.deleteBook(bookId);
        this.loadBooks(); // Обновляем список книг
      } catch (error) {
        console.error('Ошибка при удалении книги:', error);
      }
    },
    // Перейти на предыдущую страницу
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.loadBooks();
      }
    },
    // Перейти на следующую страницу
    nextPage() {
      if (this.currentPage + 1 < this.totalPages) {
        this.currentPage++;
        this.loadBooks();
      }
    },
    // Закрыть модальное окно добавления книги
    closeAddBookModal() {
      this.showAddBookModal = false;
      this.newBook = { title: '', author: '', description: '' };
      this.selectedShelfId = null;
    },
  },
  created() {
    this.loadShelves();
    this.loadBooks();
  },
};
</script>

<style scoped>
.admin-books {
  padding: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.filters {
  margin: 20px 0;
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 8px;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li button {
  background-color: #6c757d;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 4px;
  cursor: pointer;
}

li button:hover {
  background-color: #5a6268;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
}

.pagination span {
  font-size: 16px;
}

/* Стили для модального окна */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  width: 500px;
  border-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}
</style>
