<template>
  <div class="admin-dashboard">
    <h1>Административная панель</h1>
    <nav class="admin-nav">
      <router-link to="/admin/books">Книги</router-link>
      <router-link to="/admin/shelves">Полки</router-link>
      <router-link to="/admin/stats">Статистика</router-link>
      <router-link to="/admin/users">Пользователи</router-link>
    </nav>
    <div class="admin-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminDashboard'
};
</script>

<style scoped>
.admin-dashboard {
  padding: 20px;
}

.admin-nav {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.admin-nav a {
  padding: 10px 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
  color: #333333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.admin-nav a:hover {
  background-color: #c0c0c0;
}

.admin-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
}
</style>
