import api from '@/services/api';

export default {
    getBooks(params) {
        return api.get('/books', {params});
    },

    addBook(book, shelfId) {
        return api.post('/books', book, {params: {shelfId}});
    },

    deleteBook(bookId) {
        return api.delete(`/books/${bookId}`);
    },

    updateBook(bookId, book) {
        return api.put(`/books/${bookId}`, book);
    },

    getBookById(bookId) {
        return api.get(`/books/${bookId}`);
    },
};
